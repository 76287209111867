<template>
  <div>
    <div class="d-md-flex mb-3">
      <div class="ml-auto">
        <a-button
          :class="isMobile ? ['w-100', 'my-3'] : null"
          size="large"
          @click.prevent="refreshTable"
        >
          <a-icon type="sync" :spin="loadingRefresh" />
          Refresh
        </a-button>
      </div>
    </div>
    <a-table
      class="hasan-table-overflow"
      :columns="columns"
      :data-source="data"
      :pagination="false"
      :loading="loadingTable"
      bordered
    >
    <div slot="percentage" slot-scope="value">
      <a-progress :percent="Number(value)" status="active" />
    </div>
    <div slot="action" slot-scope="value, record">
      <a-button @click.prevent="toDetailAttendance(record.key)" class="text-primary border-primary" icon="book">Attendances Detail</a-button>
    </div>
  </a-table>
  </div>
</template>

<script>
const columns = [
  {
    title: 'Class',
    dataIndex: 'kelas',
    key: 'kelas',
    align: 'center',
    width: 150,
  },
  {
    title: 'Total Student',
    dataIndex: 'totalStudent',
    width: 100,
    align: 'center',
    scopedSlots: { customRender: 'totalStudent' },
  },
  {
    title: "Today's Attendance Recap",
    children: [
      {
        title: 'present',
        dataIndex: 'totalTodayStatusPresentCounted',
        key: 'totalTodayStatusPresentCounted',
        align: 'center',
        // width: 200,
      },
      {
        title: 'sick',
        dataIndex: 'totalTodayStatusSickCounted',
        key: 'totalTodayStatusSickCounted',
        align: 'center',
        // width: 200,
      },
      {
        title: 'excused',
        dataIndex: 'totalTodayStatusExcusedCounted',
        key: 'totalTodayStatusExcusedCounted',
        align: 'center',
        // width: 200,
      },
      {
        title: 'absent',
        dataIndex: 'totalTodayStatusAbsentCounted',
        key: 'totalTodayStatusAbsentCounted',
        align: 'center',
        // width: 200,
      },
      {
        title: 'not absent yet',
        dataIndex: 'totalTodayStatusNotAbsentYetCounted',
        key: 'totalTodayStatusNotAbsentYetCounted',
        align: 'center',
        // width: 200,
      },
    ],
  },
  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
    align: 'center',
    width: 200,
    scopedSlots: { customRender: 'action' },
  },
]
export default {
  data() {
    return {
      loadingTable: false,
      loadingRefresh: false,
      type: 'mid',
      columns,
      data: [],
      idKelas: this.$route.params.id,
    }
  },
  methods: {
    toDetailAttendance(idKelas) {
      console.log(idKelas)
      this.$router.push({ name: 'Curriculum Detail Attendance Report', params: { id_kelas: idKelas } })
    },
    handleChangeType() {
      this.fetchDataTable(this.type)
    },
    refreshTable() {
      this.loadingRefresh = true
      this.fetchDataTable()
    },
    async fetchDataTable() {
      try {
        this.loadingTable = true
        const res = await this.$store.dispatch('curriculum/FETCH_DAILY_ATTENDANCE')
        this.loadingRefresh = false
        this.loadingTable = false
        this.data = res.map(row => {
          return {
            ...row,
          }
        })
        return new Promise((resolve, reject) => resolve())
      } catch (err) {
        console.log(err)
        return new Promise((resolve, reject) => reject(err))
      }
    },
  },
  computed: {
    isMobile() {
      return this.$store.getters.isMobile
    },
    isTablet() {
      return this.$store.getters.isTablet
    },
    isDesktop() {
      return !this.isMobile && !this.isTablet
    },
  },
  mounted() {
    this.fetchDataTable()
  },
}
</script>

<style>

</style>
